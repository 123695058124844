import React, { useState } from "react";
import "./App.css";
import moment from "moment";
import "moment/locale/pt-br";
import alan from "./asset/alan.jpg";
import carmino from "./asset/carmino.jpg";
import jones from "./asset/jones.jpg";
import maycon from "./asset/maycon.jpg";
import ruziel from "./asset/ruziel.jpg";
import joao from "./asset/joao_schiavon.jpg";

import {
  Table,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

function App() {
  let arrayDaysResource = [];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [arrayDaysResourceFilter, setarrayDaysResourceFilter] =
    useState(arrayDaysResource);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  //moment.locale('pt-br');
  const objctJones = {
    name: "JONES CARVALHO",
    phone: "https://wa.me/5516981479372",
    photo: jones,
  };
  const objctCarmino = {
    name: "CARMINO VELLUTTO",
    phone: "https://wa.me/5516981516604",
    photo: carmino,
  };
  const objctMaycon = {
    name: "MAYCON VELLUDO",
    phone: "https://wa.me/5516997516788",
    photo: maycon,
  };
  const objctRuziel = {
    name: "RUZIEL ALVES",
    phone: "https://wa.me/5511972526109",
    photo: ruziel,
  };
  const objctAlan = {
    name: "ALAN PATRICK",
    phone: "https://wa.me/5516997275728",
    photo: alan,
  };
  const objctJoao = {
    name: "JOÃO SCHIAVON",
    phone: "https://wa.me/5516996015882",
    photo: joao,
  };

  var resources = [
    {
      ...objctJoao,
      nivelSupport: [
        objctAlan,
        objctMaycon,
        objctRuziel,
        objctCarmino,
        objctJones,
      ],
    },
    {
      ...objctAlan,
      nivelSupport: [
        objctJoao,
        objctMaycon,
        objctRuziel,
        objctCarmino,
        objctJones,
      ],
    },
    {
      ...objctCarmino,
      nivelSupport: [
        objctJoao,
        objctAlan,
        objctMaycon,
        objctRuziel,
        objctJones,
      ],
    },
    {
      ...objctMaycon,
      nivelSupport: [
        objctJoao,
        objctAlan,
        objctRuziel,
        objctCarmino,
        objctJones,
      ],
    },
    {
      ...objctRuziel,
      nivelSupport: [
        objctJoao,
        objctAlan,
        objctMaycon,
        objctCarmino,
        objctJones,
      ],
    },
  ];

  const endOfMonth = moment().clone().endOf("month").format("DD");

  var currentDay = moment().format("DD");

  const dateStart = moment().clone().startOf("month").format("MM/DD/YYYY");

  let countResources = 0;
  let daysLoop = moment(dateStart);

  for (let index = 1; index <= parseInt(endOfMonth); index++) {
    arrayDaysResource.push({
      resource: resources[countResources],
      date: daysLoop,
    });
    daysLoop = moment(daysLoop).add(1, "d");
    if (countResources === resources.length - 1) countResources = 0;
    else countResources++;
  }

  let ScaleOfDay = arrayDaysResource[parseInt(currentDay) - 1];

  function filterArraySource(nameFilter) {
    // eslint-disable-next-line
    if (nameFilter != null && nameFilter != undefined && nameFilter != "")
      setarrayDaysResourceFilter(
        arrayDaysResource.filter((x) => x.resource.name === nameFilter)
      );
    else {
      setarrayDaysResourceFilter(arrayDaysResource);
    }
  }

  return (
    <div style={{ padding: "1rem" }}>
      <center>
        <h5>Escala de Plantão Time AccessControl</h5>
        <Card
          style={{
            width: "18rem",
          }}
        >
          <CardBody>
            <CardTitle tag="h5">{ScaleOfDay.resource.name}</CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6">
              {moment().format("DD/MM/YYYY")}
            </CardSubtitle>
          </CardBody>
          <img
            alt={ScaleOfDay.resource.name}
            src={ScaleOfDay.resource.photo}
            width="100%"
            style={{ padding: "1rem" }}
          />
          <CardBody style={{ fontSize: "12px" }}>
            <CardText>
              ANTES DAS 9:00 E PÓS 18:00 DURANTE SEMANA, FINAL DE SEMANA E
              FERIADO
              <br />
              <br />
              Para contatar clique no link
            </CardText>

            <Button
              color="success"
              outline
              href={ScaleOfDay.resource.phone}
              target="_blank"
            >
              WhatsApp
            </Button>
            <CardText style={{ marginTop: "15px" }}>
              Caso não seja possível entrar em contato, acione o próximo nível.
              <br />
            </CardText>
            <CardHeader style={{ marginTop: "7px" }}>Nivel</CardHeader>
            <ListGroup flush style={{ float: "right" }}>
              {ScaleOfDay.resource.nivelSupport.map((nivel, index) => {
                return (
                  <ListGroupItem key={index}>
                    {index +
                      1 +
                      " - " +
                      nivel.name.substring(0, nivel.name.indexOf(" "))}
                    <Button
                      color="success"
                      outline
                      href={nivel.phone}
                      target="_blank"
                      style={{
                        float: "right",
                        fontSize: "10px",
                        marginLeft: "30px",
                      }}
                    >
                      WhatsApp
                    </Button>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </CardBody>
        </Card>
        <br />
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle caret> Recursos </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                filterArraySource();
              }}
            >
              <div>- TODOS -</div>
            </DropdownItem>
            {resources.map((res, index) => {
              return (
                <DropdownItem
                  key={index}
                  onClick={() => {
                    filterArraySource(res.name);
                  }}
                >
                  {res.name}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </center>
      <Table striped>
        <thead>
          <tr>
            <th>DIA</th>
            <th>ESCALA</th>
            <th>NIVEL</th>
          </tr>
        </thead>
        <tbody>
          {arrayDaysResourceFilter.map((res, index) => {
            return (
              <tr
                key={index}
                style={
                  res.date.locale("pt-br").format("DD") === currentDay
                    ? { fontSize: "12px", backgroundColor: "palegreen" }
                    : { fontSize: "12px" }
                }
              >
                <th scope="row" style={{ alignItems: "center" }}>
                  <div style={{ fontSize: "12px" }}>
                    {res.date.locale("pt-br").format("DD") +
                      " - " +
                      (res.date.locale("pt-br").format("dddd").indexOf("-") ===
                      -1
                        ? res.date
                            .locale("pt-br")
                            .format("dddd")
                            .substring(0, 3)
                        : res.date
                            .locale("pt-br")
                            .format("dddd")
                            .substring(
                              0,
                              res.date
                                .locale("pt-br")
                                .format("dddd")
                                .indexOf("-")
                            )
                            .substring(0, 3))}
                  </div>
                </th>
                <td>
                  <a
                    href={res.resource.phone}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    {res.resource.name}
                  </a>
                </td>
                <td style={{ fontSize: "10px" }}>
                  {res.resource.nivelSupport.map((contact, idx) => {
                    return (
                      <a
                        key={idx}
                        href={contact.phone}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "inherit", textDecoration: "none" }}
                      >
                        {contact.name.substring(0, contact.name.indexOf(" ")) +
                          " | "}
                      </a>
                    );
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default App;
